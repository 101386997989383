<template>
  <div>
    <div class="search-result-banner">
      <div class="bg-filter"></div>
      <div class="text-wrapper">
        <div>“老吾老以及人之老”</div>
      </div>
    </div>
    <div class="search-input-wrapper">
      <input
        type="text"
        placeholder="请输入关键词"
        :value="searchKeyword"
        @input="updateSearchKeyword"
        @keyup.enter="handleSearchBtnClick"
      />
      <div class="search-btn" @click="handleSearchBtnClick">
        <span class="iconfont search">&#xe653;</span>
        <span>搜索</span>
      </div>
    </div>
    <div class="result">
      <div class="result-tip" v-if="result.length">
        Hi, 您目前搜索关键字“{{ tipKeyword }}”，共找到{{ result.length }}个内容
      </div>
      <ul>
        <li v-for="(item, index) in newList[activeIndex]" :key="index">
          <router-link :to="{ name: 'Details', params: { id: index } }">
            <div class="result-title">
              {{ item.title }}
            </div>
            <div class="result-abstract" v-if="item.abstract">
              {{ item.abstract }}
            </div>
          </router-link>
        </li>
      </ul>
    </div>
    <!-- 分页 -->
    <Pagination
      :pageCount="newList.length"
      :currentPage="activeIndex"
      @prePage="handlePrePage"
      @nextPage="handleNextPage"
      @switchPage="handleSwitchPage($event)"
    />
    <ToTop />
  </div>
</template>

<script>
import Pagination from "@/components/Pagination";
import ToTop from "@/components/ToTop.vue";

import { mapState } from "vuex";

export default {
  name: "SearchResult",
  components: {
    Pagination,
    ToTop
  },
  data() {
    return {
      result: [],
      currentIndex: 0,
      // 搜索完成之后的提示关键词
      tipKeyword: "",
      // 假设的搜索结果数据
      resultList: [
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract:
            "8年，9000万参与人次，汇聚“一元”成“亿元”，惠及云、贵川等8省份的多个贫困山区，近40万人次的小学生受益。这是“捐一元”项目通过8年努力，向公众提交......"
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract:
            "8年，9000万参与人次，汇聚“一元”成“亿元”，惠及云、贵川等8省份的多个贫困山区，近40万人次的小学生受益。这是“捐一元”项目通过8年努力，向公众提交......"
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知2",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract:
            "8年，9000万参与人次，汇聚“一元”成“亿元”，惠及云、贵川等8省份的多个贫困山区，近40万人次的小学生受益。这是“捐一元”项目通过8年努力，向公众提交......"
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知33",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知3",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract:
            "8年，9000万参与人次，汇聚“一元”成“亿元”，惠及云、贵川等8省份的多个贫困山区，近40万人次的小学生受益。这是“捐一元”项目通过8年努力，向公众提交......"
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知4",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知5",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知5",
          abstract: ""
        },
        {
          title: "[ 新闻资讯 ]关于2019年度逐梦公益令项目申报通知5",
          abstract: ""
        }
      ]
    };
  },
  computed: {
    ...mapState({
      searchKeyword: state => state.searchKeyword
    }),
    newList() {
      let newList = [];
      for (let i = 0; i < this.resultList.length; i += 10) {
        let segment = this.resultList.slice(i, i + 10);
        newList.push(segment);
      }
      return newList;
    },
    activeIndex() {
      return this.currentIndex;
    }
  },
  methods: {
    // 分页
    handlePrePage() {
      // console.log("pre");
      if (this.currentIndex == 0) {
        return;
      }
      this.currentIndex -= 1;
    },
    handleNextPage() {
      // console.log("next");
      if (this.currentIndex == this.newList.length - 1) {
        return;
      }
      this.currentIndex += 1;
    },
    handleSwitchPage(index) {
      this.currentIndex = index;
    },
    handleSearchBtnClick() {
      this.tipKeyword = this.$store.state.searchKeyword;
      // 假设点击的时候获取到搜索结果
      this.result = this.resultList;
      // console.log("搜索关键词:", this.searchKeyword);
    },
    updateSearchKeyword(e) {
      this.$store.commit("updateSearchKeyword", e.target.value);
    }
  },
  mounted() {
    // console.log("关键词：", this.searchKeyword);
    this.handleSearchBtnClick();
  }
};
</script>

<style lang="scss" scoped>
.search-result-banner {
  background-image: url(../../assets/images/search-result-banner.jpg);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  color: #fff;
  text-align: center;
  width: 100%;
  height: 400px;
  background-size: cover;
  align-items: center;
  position: relative;
  // scroll-behavior: smooth;
  // 滤镜
  .bg-filter {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(24, 65, 98, 0.418);
  }
  // 文字
  .text-wrapper {
    z-index: 10;
    div:first-child {
      margin-top: 90px;
      font-size: 40px;
    }
  }
}
.search-input-wrapper {
  width: 100%;
  height: 190px;
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
  input {
    width: 970px;
    height: 60px;
    outline: none;
    background-color: #fff;
    text-indent: 2em;
    box-sizing: border-box;
    border: 1px solid #fff;
  }
  input:focus {
    border: 1px solid #004898;
    border-right: none;
  }
  .search-btn {
    cursor: pointer;
    width: 140px;
    height: 60px;
    line-height: 60px;
    color: #fff;
    background-color: #004898;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background-color: #2e65a5;
    }
    span.search {
      margin-right: 10px;
    }
  }
}
.result {
  width: 1110px;
  min-height: 1020px;
  margin: 0 auto;
  .result-tip {
    height: 90px;
    line-height: 90px;
    font-size: 24px;
  }
  ul {
    padding: 0;
    li {
      // height: 90px;
      border-bottom: 1px solid #ccc;
      a {
        display: inline-block;
        width: 100%;
        height: 100%;
        padding: 30px 30px 30px 34px;
        display: flex;
        flex-direction: column;
        align-content: center;
        color: #1d1d1f;
        text-decoration: none;
      }
      .result-title {
        line-height: 30px;
        margin-bottom: 5px;
      }
      .result-abstract {
        color: #999;
      }
    }
    li:hover {
      cursor: pointer;
      color: #fff;
      background-color: #004898;
      a {
        color: #fff;
        text-decoration: none;
      }
      .result-abstract {
        color: rgb(230, 230, 230);
      }
    }
  }
}
</style>
